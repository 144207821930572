import Pechat from '@autrm/assets/pechat.png';
import Podpis from '@autrm/assets/podpis3.png';

import { ContractStyled, PechatStyled, PodpisStyled } from './styled';

export function HtmlContract() {
  return (
    <ContractStyled
      // @ts-expect-error
      onselectstart="return false"
      onMousedown="return false"
    >
      {/* <h2>ДОГОВОР №_______</h2> */}
      <PechatStyled
        src={Pechat}
        alt=""
      />
      <PodpisStyled
        src={Podpis}
        alt=""
      />
      <h3>
        <span>Утверждено</span>
      </h3>
      <h3>
        <span>Директор ЧТУП «ВитЛайк Кофе»</span>
      </h3>
      <h3>
        <span>Приказ от 04.03.2024 № 2</span>
      </h3>
      <h3>
        <span>________ В.А. Кагукин</span>
      </h3>
      <br />
      <br />
      <h3>Публичный договор</h3>
      <p>оказания информационных услуг</p>
      <p>
        Настоящий Публичный договор (далее по тексту - «Договор») является договором между ЧТУП
        «ВитЛайк Кофе» (АВТОРУМ.BY) (далее по тексту - «Исполнитель») и Заказчиком, в котором
        определены условия оказания Исполнителем Заказчику Услуг, а также взаимные права и
        обязанности сторон.
      </p>
      <br />
      <br />
      {/* <p>
        <strong>
          Гражданин (ка) _______________________________________________________________,
        </strong>
        именуемый(ая) в дальнейшем «Заказчик», с одной стороны и{' '}
        <strong>Частное торговое унитарное предприятие «ВитЛайкКофе»</strong>, именуемое в
        дальнейшем «Исполнитель», в лице директора Кагукина Виталия Александровича, действующего на
        основании Устава, с другой стороны, а вместе именуемые в дальнейшем «Стороны», заключили
        настоящий договор о нижеследующем:
      </p> */}
      <h3>1 ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА И ПРЕДМЕТ ДОГОВОРА</h3>
      <p>
        <strong>1.1</strong> Настоящий Договор является публичным договором (статья 396 Гражданского
        кодекса Республики Беларусь), в соответствии с которым Исполнитель принимает на себя
        обязательство по оказанию Услуг в отношении каждого из Заказчиков, кто обратится к
        Исполнителю для получения таких Услуг.
      </p>
      <p>
        <strong>1.2</strong> Размещение текста настоящего Договора на Сайте Исполнителя по адресу
        https://auto-room.by/ является публичной офертой Исполнителя в соответствии с пунктом 2
        статьи 407 Гражданского кодекса Республики Беларусь.
      </p>
      <p>
        <strong>1.3</strong> Заключение Договора производится путем присоединения Заказчика к
        Договору, то есть посредством принятия (акцепта) Заказчиком условий Договора в целом, без
        каких-либо условий, изъятий и оговорок (статья 398 Гражданского кодекса Республики
        Беларусь).
      </p>
      <p>
        <strong>1.4</strong> Акцептом оферты Заказчиком является осуществление заказа на оказание
        услуг одним из следующих способов (либо их совокупностью):
        <ul>
          <li>
            путем направления Исполнителю заявки на оказание информационных услуг в свободной форме
            при помощи систем мгновенного обмена сообщениями (мессенджеров): Telegram, Viber,
            WhatsApp;
          </li>
          <li>
            посредством перехода по ссылке, предоставленной Исполнителем после общения с Заказчиком
            через сообщение в одном из мессенджеров, с обязательным уточнением уникальной
            информации, позволяющей идентифицировать лот (транспортное средство), такой как: номер
            лота и максимальной стоимости приобретения лота (ТС) на торгах, а так же дополнительной
            информации по лоту, такой как: марка, модель, VIN, которая не является обязательной и
            может предоставляться дополнительно. (В случае, если заявка не направлялась указанным
            выше способом, а также если она содержит в себе не все обязательные сведения из перечня
            выше, заявка считается неотправленной и максимальная ставка по аукциону не
            подтверждена).
          </li>
        </ul>
      </p>
      <p>
        <strong>1.5</strong> Настоящий Договор при условии соблюдения порядка его акцепта считается
        заключенным в простой письменной форме (пункты 2, 3 статьи 404 и пункт 3 статьи 408
        Гражданского кодекса Республики Беларусь).
      </p>
      <p>
        <strong>1.6</strong> Местом заключения договора Стороны признают местонахождение
        Исполнителя.
      </p>
      <p>
        <strong>1.7</strong> В соответствии с условиями настоящего договора Исполнитель обязуется
        оказывать Заказчику по его заявке информационные и консультативные услуги, связанные с
        участием в интересах Заказчика в торгах на аукционных или дилерских площадках (далее –
        «торговая площадка») по приобретению у третьих лиц, выбранного Заказчиком автомобиля и (или)
        иного транспортного средства (далее по тексту – «транспортное средство»), с указанными
        Заказчиком характеристиками, а также с порядком доставки такого транспортного средства на
        территорию Республики Беларусь и его таможенным оформлением (далее по тексту – «услуги»), а
        Заказчик обязуется оплатить и принять оказанные услуги.
      </p>
      <p>
        <strong>1.8</strong> Термины и определения, используемые Сторонами при исполнении договора:
      </p>
      <p>
        <strong>1.8.1</strong> адрес электронной почты – индивидуальный адрес, указанный Заказчиком
        для получения и отправки электронных писем, используемый для коммуникации с Исполнителем.
      </p>
      <p>
        <strong>1.8.2</strong> аккаунт или учетная запись – персональная страница или личный
        кабинет, который создан Исполнителем или по его поручению третьим лицом после регистрации на
        сайте торговой площадке или ином интернет-сайте для осуществления услуг Заказчику.
      </p>
      <p>
        <strong>1.8.3</strong> аукционный сбор – не включаемая в ставку денежная сумма, которая
        предоставляется к оплате согласно счету, выставляемого торговой площадкой по каждому
        конкретному лоту в случае положительного результата торгов.
      </p>
      <p>
        <strong>1.8.4</strong> груз – транспортное средство в процессе перевозки, транспортировки,
        перемещения Заказчику.
      </p>
      <p>
        <strong>1.8.5</strong> дилер – компания-продавец с регистрацией на территории страны,
        Евросоюза соответствующего рынка, действующая с ведома Исполнителя в интересах Заказчика для
        регистрации и участия в торгах.
      </p>
      <p>
        <strong>1.8.6</strong> заявка – обращение Заказчика к Исполнителю, содержащее предложение
        Заказчика по оказанию услуг Исполнителем в рамках настоящего договора и направляемое в
        свободной форме, содержащее обязательные условия, согласно условий настоящего договора и
        направляемое Заказчиком Исполнителю для последующего акцепта Исполнителем.
      </p>
      <p>
        <strong>1.8.7</strong> задаток – денежная сумма, уплачиваемая Заказчиком Исполнителю в счет
        причитающихся от Заказчика по договору платежей в доказательство заключения настоящего
        договора и в обеспечение его исполнения, которая остается у Исполнителя при неисполнении или
        ненадлежащем исполнении отдельных условий договора Заказчиком.
      </p>
      <p>
        <strong>1.8.8</strong> инвойс – документ, предъявляемый для оплаты Заказчиком услуг третьих
        лиц в рамках настоящего договора.
      </p>
      <p>
        <strong>1.8.9</strong> лот – базовая единица (транспортное средство) купли и продажи, за
        которую осуществляются торги.
      </p>
      <p>
        <strong>1.8.10</strong> мессенджер – программа (приложение) для смартфона, телефона или
        персонального компьютера, позволяющая Заказчику и Исполнителю мгновенно обмениваться между
        собой текстовыми сообщениями, документами, заявками, фото и видео файлами, совершать звонки
        при помощи аудио и видео связи, а также согласовывать документы необходимые для исполнения
        условий настоящего договора. В качестве мессенджеров могут использоваться Telegram, Viber,
        WhatsApp, адрес электронной почты и иные удобные сторонам.
      </p>
      <p>
        <strong>1.8.11</strong> номер телефона – указанный Заказчиком номер мобильного телефона, по
        которому возможно осуществление с ним связи и коммуникации, в том числе с использованием
        мессенджера.
      </p>
      <p>
        <strong>1.8.12</strong> перевозчик – компания, осуществляющая доставку транспортного
        средства Заказчику.
      </p>
      <p>
        <strong>1.8.13</strong> положительный результат торгов – покупка выбранного Заказчиком
        транспортного средства через торговую площадку (сыгранный лот).
      </p>
      <p>
        <strong>1.8.14</strong> сайт Исполнителя – информационная единица в интернете по адресу
        (домену): https://auto-room.by/, ресурс из веб-страниц (документов, программ, приложений),
        которые объединены общей темой и связаны между друг с другом с помощью ссылок.
      </p>
      <p>
        <strong>1.8.15</strong> ставка – максимальное ценовое предложение Заказчика поданное в
        свободной форме и содержащееся в Заявке за конкретное транспортное средство ( лот ).
      </p>
      <p>
        <strong>1.8.16</strong> третьи лица – физические или юридические лица (брокер, дилер,
        перевозчик, экспедитор, таможенный агент, владелец торговой площадки, эксперт и др.),
        призванные Исполнителем для оказания отдельных услуг по исполнению условий настоящего
        договора.
      </p>
      <p>
        <strong>1.8.17</strong> транспортно-экспедиционные услуги – транспортно-экспедиционные
        услуги, оказываемые третьими лицами по доставке транспортного средства Заказчику после
        положительного результата торгов.
      </p>
      <p>
        <strong>1.8.18</strong> торговая площадка – интернет-сайт дилера либо организатора аукциона,
        с которого осуществляется продажа транспортного средства по установленным правилам.
      </p>
      <p>
        <strong>1.8.19</strong> торги – продажа транспортных средств через интернет-сайты
        посредствам объявления всем желающим и проводимая в виде аукциона, победителем которых
        становится участник, сделавший последнюю наиболее высокую ценовую ставку.
      </p>
      <p>
        <strong>1.8.20</strong> услуги – консультативные и информационные услуги по предмету
        настоящего договора, связанные с поиском и подбором по заявке Заказчика необходимого ему
        транспортного средства на рынках Соединенных Штатов Америки, Европейского союза, Южной
        Кореи, Объединенных Арабских Эмиратов, Китайской Народной Республики и на других мировых
        рынках.
      </p>
      <br />
      <br />
      <h3>2 ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
      <p>
        <strong>2.1</strong> Заказчик обязан:
      </p>
      <p>
        <strong>2.1.1</strong> своевременно предоставлять Исполнителю заявку, документы и
        информацию, необходимых для исполнения принятых обязательств Исполнителем по договору, при
        этом Заказчик самостоятельно определяет форму и содержание заявки; выбрать торговую площадку
        и лот, назначить максимальную для торгов ставку;
      </p>
      <p>
        <strong>2.1.2</strong> оплатить услуги Исполнителя в соответствии с условиями настоящего
        договора;
      </p>
      <p>
        <strong>2.1.3</strong> оплатить направленный Исполнителем посредствам мессенджера инвойс за
        сыгранный лот в размере стоимости приобретенного транспортного средства и аукционного сбора;
      </p>
      <p>
        <strong>2.1.4</strong> предоставить Исполнителю посредствам мессенджера документ,
        подтверждающий оплату инвойса;
      </p>
      <p>
        <strong>2.1.5</strong> своевременно оплачивать услуги транспортных компаний, осуществляющих
        транспортировку транспортного средства Заказчика, своевременно оплачивать расходы связанные
        с доставкой транспортного средства и возможные в связи с этим издержки, а также услуги,
        оказываемые представителями склада временного хранения (СВХ) согласно переданным
        Исполнителем инвойсам;
      </p>
      <p>
        <strong>2.1.6</strong> своевременно оплачивать расходы по таможенному оформлению
        приобретенного транспортного средства, по досмотру, инспектированию и проверке, любой иной
        процедуре, инициированной таможенными органами любой из стран на основании переданных
        Исполнителем инвойсов и(или) переданные платежные поручения или иные документы на оплату от
        соответствующих органов и третьих лиц.
      </p>
      <p>
        <strong>2.1.7</strong> выполнить все необходимые действия и формальности, связанные с
        покупкой транспортного средства, в том числе осуществить проверку транспортного средства до
        начала проведения по нему торгов из возможных и доступных источников на предмет его
        технического состояния (характере повреждений, юридической чистоты), а при отсутствии
        специальных знаний о свойствах и характеристиках такого транспортного средства и его
        повреждений привлечь для консультаций соответствующего специалиста, в числе для определения
        стоимости ремонтных (восстановительных ) работ для такого транспортного средства;
      </p>
      <p>
        <strong>2.1.8</strong> внести задаток в порядке и сроки, предусмотренные Договором;
      </p>
      <p>
        <strong>2.1.9</strong> возмещать причинённые Исполнителю убытки за неисполнение и (или)
        ненадлежащее исполнение условий настоящего договора;
      </p>
      <p>
        <strong>2.1.10</strong> возмещать Исполнителю неустойку в случае неисполнения и (или)
        ненадлежащего исполнения условий настоящего договора;
      </p>
      <p>
        <strong>2.1.11</strong> самостоятельно застраховать транспортное средство от всех видов
        рисков, связанных с доставкой транспортного средства и другими рисками. В случае отсутствия
        страхования транспортного средства Заказчик несет все риски, связанные с транспортировкой,
        доставкой и(или) любым иным перемещением транспортного средства в пункт назначения.
      </p>
      <p>
        <strong>2.1.12</strong> своевременно выполнять взятые на себя обязательства, в соответствии
        с условиями настоящего договора.
      </p>
      <p>
        <strong>2.2</strong> Заказчик имеет право:
      </p>
      <p>
        <strong>2.2.1</strong> изменять содержание заявки по согласованию с Исполнителем и
        последующим акцептом Исполнителем посредствам мессенджера и только в порядке,
        предусмотренном настоящим договором;
      </p>
      <p>
        <strong>2.2.2</strong> самостоятельно проводить анализ найденных и предложенных Исполнителем
        лотов, размещенных на торговых площадках;
      </p>
      <p>
        <strong>2.3</strong> Исполнитель обязан:
      </p>
      <p>
        <strong>2.3.1</strong> добросовестно исполнять взятые на себя обязательства по настоящему
        договору;
      </p>
      <p>
        <strong>2.3.2</strong> разместить ставку на торговой площадке в интересах Заказчика после
        выбора Заказчиком транспортного средства, выставленного на продажу; совершать иные действия,
        связанные с участием Заказчика в торгах, а также предусмотренные настоящим договором;
      </p>
      <p>
        <strong>2.3.3</strong> передавать Заказчику информацию и результат ее обработки по средствам
        мессенджера и(или) путем телефонных переговоров;
      </p>
      <p>
        <strong>2.3.4</strong> известить Заказчика о результате торгов, а при положительном
        результате торгов – направить Заказчику инвойс для оплаты сыгранного для Заказчика лота;
      </p>
      <p>
        <strong>2.3.5</strong> предоставить Заказчику фотографии транспортного средства из порта
        отгрузки при приобретении транспортного средства при помощи торговых площадок (для рынка
        США);
      </p>
      <p>
        <strong>2.3.6</strong> информировать Заказчика о местонахождении транспортного средства на
        всех этапах доставки транспортного средства в пункт назначения по средствам мессенджера;
      </p>
      <p>
        <strong>2.3.7</strong> консультировать Заказчика по всем вопросам, связанным с предметом
        настоящего договора
      </p>
      <p>
        <strong>2.4</strong> Исполнитель имеет право:
      </p>
      <p>
        <strong>2.4.1</strong> использовать все возможности по выполнению своих обязательств по
        договору;
      </p>
      <p>
        <strong>2.4.2</strong> выступать от своего имени, но по поручению и за счет Заказчика в
        торгах, а также привлекать третьих лиц для исполнения своих обязательств по настоящему
        договору без согласия Заказчика, в том числе на использование аккаунтов у таких третьих лиц
        для участия в торгах в интересах Заказчика;
      </p>
      <p>
        <strong>2.4.3</strong> требовать своевременной оплаты от Заказчика за оказываемые услуги по
        договору, а также зачисления денежных средств Заказчиком в пользу третьих лиц по переданным
        Исполнителем инвойсам
      </p>
      <p>
        <strong>2.4.4</strong> контролировать доставку приобретенного Заказчиком транспортного
        средства и предоставлять информационное сопровождение такой доставки.
      </p>
      <p>
        <strong>2.4.5</strong> в случае несвоевременной оплаты Заказчиком за лот и(или) доставки
        транспортного средства, предъявить Заказчику для оплаты сумму неустойки до прибытия в пункт
        назначения.
      </p>
      <p>
        <strong>2.4.6</strong> удерживать транспортное средство до полного погашения Заказчиком
        возникшей у него задолженности перед Исполнителем.{' '}
      </p>
      <br />
      <br />
      <h3>3 СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</h3>
      <p>
        <strong>3.1</strong> Стоимость услуг по настоящему договору составляет (вознаграждение
        Исполнителя): <strong>Семьсот (00 копеек ) белорусских рублей. (700 BYN ) Без НДС.</strong>
      </p>
      <p>
        <strong>3.2</strong> В течении 2 (двух) рабочих дней с момента заключения договора Заказчик:
        <ul>
          <li>
            производит оплату Исполнителю стоимости услуг по настоящего договору на условиях 100 %
            предоплаты;
          </li>
          <li>вносит сумму задатка на расчетный счет Исполнителя.</li>
        </ul>
      </p>
      <p>
        <strong>3.3</strong> Оплата стоимости услуг производится через отделение банка либо систему
        ЕРИП, либо иным способом в соответствии с законодательством.
      </p>
      <p>
        <strong>3.4</strong> Банковские расходы при осуществлении платежей по настоящему договору
        несет Заказчик.
      </p>
      <p>
        <strong>3.5</strong> Датой платежа за услуги по п.3.2. является день зачисления денежных
        средств на расчетный счет Исполнителя.
      </p>
      <br />
      <br />
      <h3>4 ПОРЯДОК ОКАЗАНИЯ УСЛУГ.</h3>
      <p>
        <strong>4.1</strong> Исполнитель приступает к оказанию услуг, согласно условиям настоящего
        договора, после получения от Заказчика 100% оплаты стоимости услуг (п.п. 3.2 договора) и при
        условии внесения суммы задатка (п.п. 4.6.1.2. договора).
      </p>
      <p>
        <strong>4.2</strong> Стороны пришли к соглашению, что Заявка может быть направлена
        Исполнителю различными способами: путем телефонного звонка Исполнителю, посредствам
        мессенджера, иным способом. Фактом подтверждения (акцептом) такой Заявки Исполнителем будет
        являться совершение Исполнителем действий, свидетельствующих о начале выполнения условий,
        содержащихся в Заявке. Заявка должна содержать обязательно: марку транспортного средства,
        модель транспортного средства, номер лота, наименование торговой площадки, а также
        максимальную стоимость за транспортное средство, которую Заказчик готов предложить на
        торгах. Стороны пришли к соглашению, что Заявка может быть согласована Сторонами в случаях,
        когда Заказчик присылает посредствам мессенджера ссылки с интернет-источников торговых
        площадок, фотографии транспортного средства (и)или скриншоты с таковых площадок с указанием
        максимальной ставки за высланный Исполнителю лот, равно как и Исполнитель может получать
        подтверждение максимальной ставки за лот от Заказчика по высланным Заказчику ссылкам на
        интернет-источники торговых площадок. Во всех остальных случаях для Исполнителя факт
        подтверждения Заказчиком ставки за лот является оплата Заказчиком задатка в соответствии с п
        4.1 и п.4.6. <br />
        <br />
        Стоимость подаваемого Исполнителем по поручению и за счет Заказчика на торговых площадках
        предложения о покупке выбранного Заказчиком транспортного средства (лота) не должна
        превышать стоимость, указанную Заказчиком в заявке, за исключением случаев, когда Заказчик
        посредствам мессенджера и(или) телефонных переговоров сообщил о таком превышении.
      </p>
      <p>
        <strong>4.3</strong> Учитывая специфику проведения торгов торговыми площадками, Стороны
        пришли к соглашению, что в процессе проведения торгов по покупке выбранного Заказчиком
        транспортного средства, последний имеет право изменять максимальную стоимость, указанную за
        транспортное средство в заявке в следующем порядке: Исполнитель в ходе проведения торгов
        уведомляет Заказчика по средствам мессенджера о превышении стоимости, указанной в заявке.
        Заказчик, получив такое уведомление, обязан сообщить по средствам мессенджера Исполнителю о
        принятии либо не принятии Заказчиком изменённой ставки. Время принятия следующей ставки от
        Заказчика до 17 ч. 00 мин. по местному времени. В случае неполучения ответа от Заказчика,
        Исполнитель имеет право отказаться от дальнейшего участия в торгах по покупке лота.
      </p>
      <p>
        <strong>4.4</strong> Подтверждением сыгранного лота в пользу Заказчика является инвойс,
        переданный Исполнителем Заказчику по средствам мессенджера, который Заказчик обязан оплатить
        в срок указанный в п.п.4.6.2.1. договора.
      </p>
      <p>
        <strong>4.5</strong> Все сообщения и уведомления по настоящему договору производятся в
        электронном виде посредством мессенджера, а контактными реквизитами являются (но не
        ограничиваются):
      </p>
      <p>
        <strong>
          4.5.1 Исполнителя:
          <ul>
            <li>Телефон: +375291524415 (Viber/WhatsApp/Telegram)</li>
            <li>Email: autoroomby@gmail.com</li>
          </ul>
        </strong>
      </p>
      <p>
        <strong>
          4.5.2 Заказчика:
          <ul>
            <li>Телефон: __________________ (Viber/WhatsApp/Telegram)</li>
          </ul>
        </strong>
      </p>
      <p>
        Для целей урегулирования возможных разногласий по настоящему договору Стороны пришли к
        соглашению, что вся информация, указанная в мессенджерах, в том числе с использованием
        контактных данных, указанных в настоящем пункте, является надлежащим доказательством
        направлений соответствующих поручений Исполнителю от Заказчика и наоборот. Исполнитель,
        получив поручение Заказчика в мессенджере, имеет право произвести распечатку переписки с
        таким поручением.
      </p>
      <p>
        <strong>4.6</strong> Оплата производится в следующем порядке:
      </p>
      <p>
        <strong>4.6.1 Этап 1:</strong>
      </p>
      <p>
        <strong>4.6.1.1</strong> Заказчик оплачивает стоимость услуг Исполнителя по п.3.1. договора;
      </p>
      <p>
        <strong>4.6.1.2</strong> Заказчик вносит на расчетный счет Исполнителя:
        <ul>
          <li>
            задаток в размере 1200 (одна тысяча двести) белорусских рублей за каждое приобретаемое
            транспортное средство стоимостью до 25 000 (двадцати пяти тысяч) долларов США;{' '}
          </li>
          <li>
            задаток в размере 2400 (две тысячи четыреста) белорусских рублей за каждое приобретаемое
            транспортное средство стоимостью от 25 000 (двадцати пяти тысяч) долларов США и выше.
          </li>
          <li>
            адаток в размере 6400 (шесть тысяч четыреста) белорусских рублей за каждое приобретаемое
            транспортное средство стоимостью от 50 000 (пятидесяти тысяч) долларов США и выше.
          </li>
          <li>
            задаток в размере 15 000 (пятнадцати тысяч) белорусских рублей за каждое приобретаемое
            транспортное стоимостью от 70 000 (семидесяти тысяч) долларов США и выше.
          </li>
          <li>
            исполнитель оставляет за собой право устанавливать размер задатка по собственному
            желанию с уведомлением об этом Заказчика в письменной или устной форме, а также по
            средствам мессенджера если сумма приобретаемого транспортного средства выше указанных.
          </li>
        </ul>
      </p>
      <p>
        <strong>4.6.2 Этап 2:</strong>
      </p>
      <p>
        <strong>4.6.2.1</strong> Заказчик оплачивает сыгранный лот в размере стоимости
        приобретенного транспортного средства и аукционный сбор на основании инвойса (или инвойсов),
        направляемых Исполнителем посредствам мессенджера.
      </p>
      <p>
        Срок оплаты – в течение 2 (двух) банковских дней от даты завершения торгов, если иной срок
        не указан Исполнителем в мессенджере либо в инвойсе.
      </p>
      <p>
        Дата оплаты – дата зачисления денежных средств на расчетный счет, указанный в
        соответствующем инвойсе(инвойсах).
      </p>
      <p>
        <strong>4.6.2.2</strong> Заказчик оплачивает стоимость услуг третьих лиц на основании
        инвойсов, направленных Исполнителем посредствам мессенджера.
      </p>
      <p>
        Срок оплаты – в течение 2 (двух) банковских дней от даты инвойса, если иной срок не указан
        Исполнителем в мессенджере либо в инвойсе.
      </p>
      <p>
        Дата оплаты – дата зачисления денежных средств на расчетный счет, указанный в
        соответствующем инвойсе.
      </p>
      <p>
        <strong>4.7</strong> В процессе доставки транспортного средства стоимость услуг третьих лиц
        может увеличиться и отличаться от действующих на момент приобретения лота. В этом случае
        Заказчик обязан оплатить увеличенную стоимость таких услуг на основании инвойсов, переданных
        Исполнителем и в срок, указанный в п.п.4.6.2.2. договора.
      </p>
      <p>
        <strong>4.8</strong> При неоплате измененной стоимости услуг или увеличенной стоимости
        расходов связанных с транспортировкой, доставкой, любых иных расходов связанных с
        приобретением лота, Исполнитель сообщает Заказчику посредствам мессенджера фактическое место
        нахождения(адрес) транспортного средства на данном этапе перевозки, контактные данные
        площадки(стоянки), где расположено транспортное средство, контактные данные организации, где
        находятся документы на автомобиль, из которого Заказчик обязан самостоятельно организовать
        вывоз и дальнейшую доставку транспортного средства. Исполнитель в этом случае освобождается
        от ответственности по настоящему договору и вправе воспользоваться условиями,
        предусмотренными п.п.7.3, 10.4 настоящего договора.
      </p>
      <p>
        <strong>4.9</strong> Заказчик вправе расторгнуть настоящий договор в любое время с указанием
        причины или без указания таковой. В случае расторжения договора по инициативе Заказчика до
        выигрыша автомобиля (транспортного средства) на аукционе, Исполнитель обязуется вернуть
        сумму, оплаченную Заказчиком за его услуги по настоящему договору, в полном объеме не
        позднее 14 рабочих дней с даты получения Исполнителем письменного запроса от Заказчика,
        направленного заказным письмом.
      </p>
      <br />
      <br />
      <h3>5 ЗАДАТОК</h3>
      <p>
        <strong>5.1</strong> Сумма задатка, вносимая Заказчиком, не будет использоваться
        Исполнителем для его целей и нужд.
      </p>
      <p>
        <strong>5.2</strong> Задаток перечисляется на расчетный счет Исполнителя либо указанный им в
        мессенджере другой расчетный счет путем банковского перечисления, либо иным удобным для
        Заказчика способом в соответствии с законодательством. При оплате могут использоваться
        отделения банков, система расчетов ЕРИП, иные доступные расчеты.
      </p>
      <p>
        <strong>5.3</strong> Исполнитель производит возврат суммы внесенного задатка Заказчику за
        вычетом банковских комиссионных расходов по его перечислению в течение 10 (десять)
        банковских дней с момента получения заявления в письменной форме о возврате задатка от
        Заказчика при выполнении Заказчиком в совокупности следующих условий:
      </p>
      <p>
        <strong>5.3.1</strong> Заказчик надлежащим образом и в срок исполнил свои обязательства по
        п.п.4.6.2.1., 4.6.2.2 договора и денежные средства, перечисленные Заказчиком, поступили на
        банковский расчётный счет торговой площадки, на которой выигран лот без просрочки платежа;
      </p>
      <p>
        <strong>5.3.2</strong> отсутствуют штрафные санкции со стороны торговой площадки по
        выигранному для Заказчика лоту.
      </p>
      <p>
        <strong>5.4</strong> Задаток не подлежит возврату Заказчику в следующих случаях:
      </p>
      <p>
        <strong>5.4.1</strong> Заказчик отказался от выкупа данного транспортного средства (лота) по
        инвойсу предоставленному Исполнителем.
      </p>
      <p>
        <strong>5.4.2</strong> Заказчик нарушил обязательства, предусмотренные п.п.4.6.2, 4.8.
        настоящего договора;
      </p>
      <p>
        <strong>5.4.3</strong> либо Заказчик в течение 3 (трех) календарных дней от даты завершения
        торгов не выходит на связь посредствам мессенджера.
      </p>
      <p>
        В этих случаях сумма задатка остается в распоряжении Исполнителя, в том числе в счет
        погашения штрафов, выставляемых торговой площадкой по причине несоблюдения условий по выкупу
        сыгранного для Заказчика лота.
      </p>
      <br />
      <br />
      <h3>6 СДАЧА-ПРИЕМКА ОКАЗАННЫХ УСЛУГ</h3>
      <p>
        <strong>6.1</strong> Сдача-приемка оказанных услуг производится сторонами на основании актов
        сдачи-приемки оказанных услуг, являющихся первичными учетными документами, оформленных
        сторонами единолично.{' '}
      </p>
      <p>
        <strong>6.2</strong> По результатам оказанной услуги Заказчик и Исполнитель оформляют акт
        сдачи-приемки оказанных услуг, подтверждающий оказание Исполнителем услуг, единолично на
        основании пункта 6 статьи 10 Закона Республики Беларусь от 12 июля 2013 года «О
        бухгалтерском учете и отчетности» и постановления Министерства финансов Республики Беларусь
        от 12.02.2018 № 13 «О единоличном составлении первичных учетных документов».
      </p>
      <p>
        <strong>6.3</strong> Стороны пришли к соглашению, что акт сдачи-приемки оказанных услуг
        является отчётом об оказанных услугах Исполнителя.
      </p>
      <p>
        <strong>6.4</strong> Исполнитель вправе (но не обязан) передать Заказчику акт сдачи-приемки
        оказанных услуг одним из следующих способов:
        <ul>
          <li>заказным письмом с уведомлением о получении;</li>
          <li>
            нарочным (курьером) под подпись (с получением отметки о дате поступления Заказчику);
          </li>
          <li>в виде электронного документа, подписанного электронной цифровой подписью (ЭЦП);</li>
          <li>по средствам мессенджера.</li>
        </ul>
      </p>
      <br />
      <br />
      <h3>7 ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
      <p>
        <strong>7.1</strong> За неисполнение или ненадлежащее исполнение условий договора стороны
        несут ответственность в соответствии с законодательством Республики Беларусь.
      </p>
      <p>
        <strong>7.2</strong> В случае неоплаты или несвоевременной оплаты Заказчиком выставленного
        Исполнителем инвойса (п.п.4.4., 4.6.2 договора), Заказчик оплачивает Исполнителю штраф в
        размере 10 % от стоимости транспортного средства (лота), размещенного на торговой площадке,
        сыгранного и не выкупленного Заказчиком.
      </p>
      <p>
        <strong>7.3</strong> В случае неисполнения либо ненадлежащего исполнения Заказчиком
        обязательств, предусмотренных в п.п.4.6.2.2, 4.8. настоящего договора, Исполнитель вправе
        взыскать штраф в размере 10% от неоплаченной (несвоевременно оплаченной) суммы, указанной в
        инвойсе.
      </p>
      <p>
        <strong>7.4</strong> Предусмотренная договором неустойка оплачивается Заказчиком в
        белорусских рублях по курсу Национального банка Республики Беларусь на дату оплаты на
        расчётный счёт Исполнителя в течение 5(пяти) банковских дней с момента предъявления
        Исполнителем Заказчику требования об оплате неустойки.
      </p>
      <p>
        <strong>7.5</strong> Заказчик считается извещенным надлежащим образом, если Исполнитель
        располагает сведениями о получении Заказчиком направленного ему требования Исполнителя об
        оплате неустойки.
      </p>
      <p>
        Заказчик также считаются извещенным надлежащим образом, если:
        <ul>
          <li>
            Заказчик отказался от получения требования Исполнителя об оплате неустойки и такой отказ
            документально зафиксирован;
          </li>
          <li>
            заказчик не явился за получением требования Исполнителя об оплате неустойки,
            направленным ему Исполнителем, о чем имеется сообщение органа связи;
          </li>
          <li>
            требование Исполнителя об оплате неустойки, направленное Исполнителем, по указанному
            Заказчиком в п.п.11.2 настоящего договора месту жительства Заказчика, не вручено в связи
            с отсутствием Заказчика по указанному адресу (Заказчик не проживает (не находится) по
            указанному адресу), о чем имеется сообщение органа связи.
          </li>
          <li>
            Исполнитель направил Заказчику требование Исполнителя об оплате неустойки посредством
            мессенджера.
          </li>
        </ul>
      </p>
      <p>
        <strong>7.6</strong> Исполнитель не несет ответственности:
      </p>
      <p>
        <strong>7.6.1</strong> за техническое состояние и дефекты транспортного средства, купленного
        на торговой площадке;
      </p>
      <p>
        <strong>7.6.2</strong> за комплектацию транспортного средства, состояние кузова и салона,
        любые повреждения, возникшие во время доставки транспортного средства, утерю транспортного
        средства. Исполнитель не несет ответственности за любые скрытые дефекты транспортного
        средства. Все риски связанные с покупкой и последующими перемещениями транспортного средства
        и возможные риски связанные с возникновением повреждений транспортного средства несет
        Заказчик. Заказчик соглашаясь с условиями настоящего договора осведомлен о том, что
        Исполнитель не несет ответственности за такие повреждения и в случаях, когда Заказчик
        застраховал свое транспортное средство в организациях, оказывающих такие услуги – обращается
        в такие организации за получением разъяснений и требований связанных с возникшими
        повреждениями.
      </p>
      <p>
        <strong>7.6.3</strong> за убытки, возникшие по вине Заказчика в связи с нарушением им
        п.п.4.8. настоящего договора.
      </p>
      <p>
        При наступлении указанных обстоятельств, должны действовать условия страхования, которые
        Заказчик в праве предусмотреть в соглашении со страховой компанией (п.п.2.1.11 договора).
      </p>
      <br />
      <br />
      <h3>8 ФОРС-МАЖОР</h3>
      <p>
        <strong>8.1</strong> Стороны освобождаются от ответственности за частичное или полное
        невыполнение обязательств по договору, если это является следствием обстоятельств
        непреодолимой силы (запрет экспорта транспортного средства в стране первоначальной отправки;
        запрет импорта транспортного средства в стране конечной доставки; наводнение, пожар,
        землетрясение, пандемии, другие стихийные бедствия; война или военные действия, возникшие
        после заключения договора; утрата (повреждение) транспортного средства вследствие действий
        природных явление или третьих лиц; закрытие морских каналов и транспортных путей, открытых
        на день заключения настоящего договора; потопление, поломка судна; принятие государственными
        органами актов, делающих невозможным исполнение обязательств, в том числе изменение
        законодательства в любой из стран, изменение правил пограничных, а также иных служб,
        способных оказывать влияние на перемещение транспортного средства; экономические санкции,
        эмбарго и иные аналогичные по своему характеру обстоятельства).
      </p>
      <p>
        <strong>8.2</strong> Стороны обязаны в 3-х дневный срок письменно уведомить друг друга о
        наступлении обстоятельств форс-мажора. Доказательством форс-мажорных обстоятельств является
        заключение торгово-промышленной палаты.
      </p>
      <p>
        <strong>8.3</strong> На период действия форс-мажорных обстоятельств, сроки исполнения
        обязательств по договору соразмерно отодвигаются, если ни одна из сторон не потребует
        расторжения договора.
      </p>
      <br />
      <br />
      <h3>9 ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ </h3>
      <p>
        <strong>9.1</strong> Все споры и разногласия, которые могут возникнуть при исполнении,
        изменении или расторжении договора, разрешаются путем переговоров.
      </p>
      <p>
        <strong>9.2</strong> Досудебное урегулирование споров является обязательным для сторон. Срок
        рассмотрения претензий друг к друг – в течение 20 календарных дней с даты получения
        претензии.{' '}
      </p>
      <p>
        <strong>9.3</strong> Претензии направляются в письменной форме. При недостижении согласия,
        спорные вопросы подлежат рассмотрению в суде по месту нахождения Исполнителя.{' '}
      </p>
      <br />
      <br />
      <h3>10 ПРОЧИЕ УСЛОВИЯ</h3>
      <p>
        <strong>10.1</strong> Если иное не предусмотрено настоящим договором, изменения и (или)
        дополнения к настоящему договору принимаются по обоюдному соглашению сторон и имеют силу
        только в том случае, если они совершены в письменной форме и подписаны сторонами настоящего
        договора.
      </p>
      <p>
        <strong>10.2</strong> Настоящий договор составлен в двух экземплярах, по одному для каждой
        из Сторон и вступает в силу с момента его подписания Сторонами и действует до полного
        исполнения его Сторонами.
      </p>
      <p>
        <strong>10.3</strong> Заказчик, подписывая настоящий договор понимает и соглашается с тем,
        что:
      </p>
      <p>
        <strong>10.3.1</strong> Исполнитель не является продавцом и перевозчиком (экспедитором)
        транспортного средства и соответственно не несет ответственность за скрытые повреждения, а
        также за фактическое состояние транспортного средства заявленному; за техническое состояние
        узлов и агрегатов; за информацию указанную на сайтах; за наличие ключей; за соответствие
        фактического состояния транспортного средства фотографиям; за год выпуска транспортного
        средства, определяемого МРЭО ГАИ по месту регистрации;
      </p>
      <p>
        <strong>10.3.2</strong> Исполнитель не является перевозчиком и/или экспедитором, и
        соответственно вся полученная информация, которая по запросу предоставляется Заказчику со
        стороны Исполнителя является информацией, предоставленной с площадок логистических компаний
        и перевозчиков, действующих на соответствующем рынке услуг;
      </p>
      <p>
        <strong>10.3.3</strong> Исполнитель не является агентом торговых площадок, указанных в
        настоящем договоре и (или) перевозчиков (экспедиторов), и соответственно все инвойсы,
        передаваемые Исполнителем Заказчику для оплаты в рамках договора являются инвойсами
        соответствующих торговых площадок и (или) перевозчиков (экспедиторов), третьих лиц;
      </p>
      <p>
        <strong>10.3.4</strong> Исполнитель не является ремонтной автомастерской и соответственно не
        дает заключений о стоимости ремонтных (восстановительных) работ по выбранному
        (приобретенному) Заказчиком транспортному средству;
      </p>
      <p>
        <strong>10.3.5</strong> Заказчик, являясь покупателем транспортного средства, несет
        ответственность за выбор и получение транспортного средства, своевременность платежей,
        предоставление своих точных идентификационных данных; за оплату таможенных процедур,
        налоговых платежей; услуг таможенного декларанта; сертификации; регистрации транспортного
        средства в соответствующих государственных органах и организациях Республики Беларусь;
      </p>
      <p>
        <strong>10.3.6</strong> в процессе доставки, стоимость услуг перевозчика может измениться
        ввиду, обстоятельств не зависящих от Исполнителя и третьих лиц (войны и военные конфликты;
        массовые забастовки, теракты, государственные перевороты; международные санкции; эмбарго, и
        другие), в связи с чем, на Заказчика относятся дополнительные расходы;
      </p>
      <p>
        <strong>10.3.7</strong> Исполнитель не несет ответственность за возможно находящиеся в
        транспортном средстве (вещи, мусор и т.п.), а также за возникновение грибковых образований
        (плесени) на транспортном средстве в процессе осуществления перевозки;
      </p>
      <p>
        <strong>10.3.8</strong> все возможные экономические (санкционные) риски существующие на дату
        заключения договора и в период его исполнения, которые могут вызвать, в том числе просрочку
        платежей, возникших по вине Заказчика по настоящему договору, задержку доставки
        транспортного средства, а также то, что Исполнитель не может повлиять на указанные риски и
        соответственно не может нести ответственность за указанные просрочки и связанные с этим
        неблагоприятные, в том числе неблагоприятные имущественные, последствия для Заказчика;
      </p>
      <p>
        <strong>10.3.9</strong> на зачисление денежных средств по указанным Исполнителем реквизитам
        за транспортное средство и на вывоз перевозчиком транспортного средства с указанного
        торговой площадкой места требуется определенное время ввиду чего возникает вероятность в
        дополнительных расходах в виде простоев транспортного средства. Стоимость возникшего
        простоя, будет добавлена к стоимости перевозки для проведения оплаты на основании
        переданного инвойса, которую Заказчик обязан оплатить;
      </p>
      <p>
        <strong>10.3.10</strong> он самостоятельно изучает правила торговых площадок по приобретению
        транспортных средств;
      </p>
      <p>
        <strong>10.3.11</strong> приобретая транспортное средство по опции «buy it now» торговая
        площадка устанавливает более короткие сроки на оплату и вывоз транспортного средства, а при
        нарушении данного условия, Заказчик обязуется оплатить штрафы, согласно выставленного
        торговой площадкой инвойса;
      </p>
      <p>
        <strong>10.3.12</strong> он дает согласие на размещение и публикацию Исполнителем
        информации, сведений, фото по приобретенному транспортному средству на сайте Исполнителя
        либо других сторонних электронных информационных ресурсах, с целью привлечения новых
        потенциальных заказчиков, за исключением размещения и публикации серийного номера
        транспортного средства (VIN);
      </p>
      <p>
        <strong>10.3.13</strong> Заказчик проинформирован о возможности страхования всех возможных
        рисков, связанных с исполнением настоящего договора, в том числе риска утраты груза, что
        является дополнительным страхованием силами и средствами Заказчика;
      </p>
      <p>
        <strong>10.4</strong> Исполнитель вправе отказаться в одностороннем внесудебном порядке от
        исполнения настоящего договора и прекратить оказание услуг, уведомив об этом Заказчика, в
        случаях:
      </p>
      <p>
        <strong>10.4.1</strong> если Заказчик не соблюдает обязательства по настоящему договору, в
        том числе предусмотренные п.п.4.6.2, 4.8. настоящего договора;
      </p>
      <p>
        <strong>10.4.2</strong> в случае предоставления Заказчиком недостоверной или неполной
        информации либо документов, имеющих существенное значение для исполнения обязательств по
        данному договору.
      </p>
      <p>
        <strong>10.5</strong> Стороны договорились, что используют при пересчете курсы валют,
        установленные Национальным банком Республики Беларусь.
      </p>
      <p>
        <strong>10.6</strong> Договор, приложения, дополнения к нему, заключенные посредствам
        электронной и (или) факсимильной связи, имеют юридическую силу.
      </p>
      <p>
        <strong>10.7</strong> Приложение 1 – Согласие Заказчика на обработку персональных данных.
      </p>
      <br />
      <br />
      <h3>11 РЕКВИЗИТЫ И ПОДПИСИ СТОРОН</h3>
      <table>
        <tr>
          <td>
            <p>
              <strong>11.1. Исполнителя:</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>11.2. Заказчика:</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <strong>ЧТУП «ВитЛайкКофе»</strong>
            </p>
            <p>
              <strong>УНП</strong> 391417639
            </p>
            <p>
              <strong>Адрес:</strong> Республика Беларусь, г. Витебск, ул. Ленина, д. 26А{' '}
            </p>
            <p>
              <strong>Банковские реквизиты:</strong>
              <br />
              BY13ALFA30122667820010270000
              <br />
              в ЗАО «Альфа-Банк» г.Минск
              <br />
              БИК ALFABY2X
            </p>
            <p>
              <strong>Моб. телефон: </strong>
            </p>
            <p>
              <strong>E-mail:</strong>
            </p>
          </td>
          <td>
            <p>
              <strong>ФИО:</strong>
            </p>
            <p>
              <strong>Адрес:</strong>
            </p>
            <p>
              <strong>Паспорт:</strong>
            </p>
            <p>
              <strong>Кем выдан:</strong>
            </p>
            <p>
              <strong>Моб. телефон:</strong>
            </p>
            <p>
              <strong>E-mail:</strong>
            </p>
          </td>
        </tr>
      </table>
    </ContractStyled>
  );
}
