export function MapWidget() {
  return (
    <>
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A766613ef229943c161b29fbe044c7f59aa8d5b762c5ea77c376bebdb0b073adc&amp;source=constructor"
        width="100%"
        height="400"
        style={{
          border: 0,
        }}
      />
    </>
  );
}
