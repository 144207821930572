// import PlayIcon from '@autrm/assets/icons/play.svg';
import AuctionIcon from '@autrm/assets/icons/steps/auction.svg';
import DeliveryIcon from '@autrm/assets/icons/steps/delivery.svg';
import LocationIcon from '@autrm/assets/icons/steps/location.svg';
import ShipIcon from '@autrm/assets/icons/steps/ship.svg';
// import { Button } from '@autrm/common/components/button';
import { Col, Container, Row } from '@autrm/common/components/grid';
// import { SVGIcon } from '@autrm/common/components/svg-icon';

import { Work } from './Work';
import {
  HeaderStyled,
  // PilotStyled,
  RowStyled,
  SectionStyled,
  SubtitleStyled,
  TitleStyled,
} from './styled';

export function HowItWork() {
  return (
    <Container>
      <SectionStyled>
        <Row>
          <Col lg={12}>
            <HeaderStyled>
              <div>
                <TitleStyled>Из чего состоит цена на автомобиль?</TitleStyled>
                <SubtitleStyled>
                  Процесс покупки и доставки авто из Америки не быстрый, поэтому мы хотим показать
                  весь этап.
                </SubtitleStyled>
              </div>
              {/* <div>
                <Button type="blacked">
                  <SVGIcon
                    type={PlayIcon}
                    sizes={{
                      w: '15px',
                      h: '15px',
                    }}
                  />
                  <span>Watch video</span>
                </Button>
              </div> */}
            </HeaderStyled>
          </Col>
        </Row>
        <RowStyled>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="01"
              icon={AuctionIcon}
              title="Стоимость лота"
              // content="Участие в аукционе по покупке автомобиля."
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="02"
              icon={DeliveryIcon}
              title="Комиссия аукциона"
              // content="Транспортировка автомобиля в порт, для погрузки на судно."
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="03"
              icon={ShipIcon}
              title="Комиссия за перевод денег"
              // content="Транспортировка морским контейнером до порта Клайпеды"
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="04"
              icon={LocationIcon}
              title="Доставка до Клайпеды"
              // content="Оформление таможенных документов и доставка в Минск/Витебск"
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="05"
              icon={LocationIcon}
              title="Доставка из Клайпеды в Минск, Витебск, Гомель (при необходимости)"
              // content="Оформление таможенных документов и доставка в Минск/Витебск"
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="06"
              icon={LocationIcon}
              title="Таможенный сбор (50% в случае льготы)"
              // content="Оформление таможенных документов и доставка в Минск/Витебск"
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="07"
              icon={LocationIcon}
              title="Утиль сбор + хранение + таможенное оформление"
              // content="Оформление таможенных документов и доставка в Минск/Витебск"
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="08"
              icon={LocationIcon}
              title="Ремонт автомобиля"
              // content="Оформление таможенных документов и доставка в Минск/Витебск"
            />
          </Col>
          <Col
            lg={12}
            md={8}
            sm={4}
            // mdMargin="0 0 48px"
            // smMargin="0 0 24px"
          >
            <Work
              step="09"
              icon={LocationIcon}
              title="Услуги компании"
              // content="Оформление таможенных документов и доставка в Минск/Витебск"
            />
          </Col>
        </RowStyled>
        {/* <Row>
          <Col
            lg={12}
            justifyContent="center"
          >
            <PilotStyled>
              <strong>Excellent!</strong>
              <img
                src="assets/img/home1/icon/trustpilot-star2.svg"
                alt=""
              />
              <p>
                5.0 Rating out of <strong>5.0</strong> based on{' '}
                <a href="/#">
                  <strong>&nbsp;245354&nbsp;</strong>reviews
                </a>
              </p>
              <img
                src="assets/img/home1/icon/trustpilot-logo.svg"
                alt=""
              />
            </PilotStyled>
          </Col>
        </Row> */}
      </SectionStyled>
    </Container>
  );
}
