import { SVGIcon } from '@autrm/common/components/svg-icon';

import { ContentStyled, IconStyled, StepStyled, TitleStyled, WorkStyled } from './styled';
import type { WorkProps } from './tyles';

export function Work({ title, icon, step, content }: WorkProps) {
  return (
    <WorkStyled>
      <StepStyled>
        <span>{step}</span>
      </StepStyled>
      <TitleStyled>{title}</TitleStyled>
      <IconStyled>
        <SVGIcon
          type={icon}
          sizes={{
            w: '36px',
            h: '36px',
          }}
        />
      </IconStyled>
      {content && <ContentStyled>{content}</ContentStyled>}
    </WorkStyled>
  );
}
