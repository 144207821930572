export const userAgreement = (
  <>
    <p>
      Настоящее пользовательское соглашение (далее – Соглашение) определяет условия использования
      Пользователями материалов и сервисов сайта www.Auto-Room.by (далее — «Сайт»).
    </p>
    <p>
      <br />
      <strong>1. Термины и определения</strong>
    </p>
    <p>
      1.1. Персональные данные - основные и дополнительные персональные данные Пользователя,
      позволяющие идентифицировать такое лицо.
      <br />
      1.2. Пользователь – лицо, имеющее доступ к сайту www.Auto-Room.by, посредством сети Интернет и
      использующее сайт www.Auto-Room.by
    </p>
    <p>
      <br />
      <strong>2. Предмет Соглашения</strong>
    </p>
    <p>
      2.1. Настоящее Пользовательское соглашение (далее – Соглашение) заключается между
      ЧТУП&nbsp;«ВитЛайк Кофе» и Пользователем и определяет условия использования Сайтом, а также
      взаимные права и обязанности сторон, возникающие в связи с использованием Сайта.
    </p>
    <p>
      <br />
      <strong>3. Заключение Соглашения. Использование функционала Интернет-ресурса</strong>
    </p>
    <p>
      3.1. Настоящее Соглашение является договором присоединения, его заключение производится
      посредством принятия Пользователем условий Соглашения в порядке, предусмотренном ст. 398
      Гражданского кодекса Республики Беларусь, т.е. путем присоединения к настоящему Соглашению в
      целом без каких-либо условий, изъятий и оговорок.
      <br />
      3.2. Присоединение к настоящему Соглашению осуществляется при фактическом использовании Сайта.
      <br />
      3.5. Незнание положений настоящего Соглашения не освобождает Пользователей от ответственности
      за их нарушение.
    </p>
    <p>
      <br />
      <strong>4. Права и обязанности сторон</strong>
    </p>
    <p>
      <strong>4.1. Пользователь вправе:</strong>
      <br />
      4.1.1. в любое время расторгнуть в одностороннем порядке настоящее Соглашение.
      <br />
      <br />
      <strong>4.2. Пользователь обязан:</strong>
      <br />
      4.2.1. Представлять Исполнителю все необходимые данные для наиболее качественного оказания
      услуг по настоящему Договору;
      <br />
      4.2.2. сообщать достоверные данные о себе при пользовании Сайтом;
      <br />
      4.2.6. не совершать каких-либо действий, направленных на нарушение работы Сайта, включая, но
      не ограничиваясь, хакерские атаки, использование вредоносных программ (вирусов), изменение
      программного обеспечения Сайта и т.д.;
      <br />
      4.2.7. не использовать Сайт для целей, запрещенных законодательством Республики Беларусь.
      <br />
      <br />
      <strong>4.3. ЧТУП&nbsp;«ВитЛайк Кофе» вправе:</strong>
      <br />
      4.3.1. хранить информацию о Пользователе (IP-адреса, данные, указанные при
      регистрации/авторизации на Сайте, сообщения Пользователя);
      <br />
      4.3.2. устанавливать обязательные для заполнения Пользователем при размещении им заявки,
      использовании иного функционала Сайта поля/графы;
      <br />
      4.3.3. вносить изменения в настоящее Соглашение без предварительного уведомления Пользователей
      путем размещения на Сайте новой редакции Соглашения;
      <br />
      4.3.4. использовать информацию о Пользователе при предъявлении к ЧТУП&nbsp;«ВитЛайк Кофе»
      исков, претензий, связанных с содержанием комментария (отзыва) Пользователя, использованием им
      иного функционала Сайта, привлечении ЧТУП&nbsp;«ВитЛайк Кофе» к ответственности;
      <br />
      4.3.5. использовать информацию о действиях Пользователей в целях улучшения работы Сайта;
      <br />
      4.3.6. закрыть, приостановить работу, изменить Сайт либо его часть без предварительного
      уведомления Пользователя;
      <br />
      4.3.7. обрабатывать персональные данные Пользователей в соответствии с Политикой
      конфиденциальности, размещенной на Сайте;
      <br />
      <br />
      <strong>4.4. ЧТУП&nbsp;«ВитЛайк Кофе» обязано:</strong>
      <br />
      4.4.1. принимать меры по защите от утраты, а также разглашения без согласия Пользователя
      персональных данных Пользователя;
      <br />
      4.4.3. предоставлять персональные данные Пользователя, по требованию органов, осуществляющих
      оперативно-розыскную деятельность, органов прокуратуры и предварительного следствия, органов
      Комитета государственного контроля, Министерства информации, налоговых органов, судов в
      порядке, установленном законодательством Республики Беларусь.
    </p>
    <p>
      <br />
      <strong>5. Ограничение ответственности</strong>
    </p>
    <p>
      5.1. Администрация не несет ответственности перед Пользователем и не возмещает Пользователю
      убытки, возникшие или возникновение которых возможно в связи с перебоями в работе провайдера,
      иных лиц или служб, доступом к Интернет-ресурсу.
    </p>
    <p>
      <br />
      <strong>6. Авторское право</strong>
    </p>
    <p>
      6.1. ЧТУП&nbsp;«ВитЛайк Кофе» является правообладателем всех материалов, размещенных на Сайте.
      Доступ к материалам Сайта предоставляется исключительно для личного использования и
      ознакомления.
      <br />
      6.2. Дальнейшее воспроизведение, распространение любым способом, копирование, передача в эфир
      для всеобщего сведения, перевод, переделка и использование любым иным способом материалов,
      размещенных на Сайте, для личного использования, без преследования коммерческих целей,
      возможно только с указанием активной ссылки на Сайт или упоминанием Сайта как источника
      информации (при использовании материалов, размещенных на Сайте, в социальных сетях).
      <br />
      6.3. К лицам, нарушающим права на интеллектуальную собственность, могут быть применены меры
      гражданско-правовой, административной и уголовной ответственности в соответствии с действующим
      законодательством Республики Беларусь.
    </p>
    <p>
      <br />
      <strong>7. Заключительные положения</strong>
    </p>
    <p>
      7.1. В настоящее Соглашение ЧТУП&nbsp;«ВитЛайк Кофе» могут быть внесены изменения и/или
      дополнения в любой момент без необходимости получения согласия Пользователя.
      <br />
      7.2. Изменения и/или дополнения в Соглашение вступают в силу с момента размещения новой
      редакции Соглашения на Сайте, если иное не предусмотрено новой редакцией Соглашения.
      <br />
      7.3. Стороны соглашаются с тем, что отсутствие письменных уведомлений о несогласии с
      отдельными положениями настоящего Соглашения признается согласием и присоединением
      Пользователя к новой редакции Соглашения.
      <br />
      7.4. Все вопросы, не урегулированные настоящим Соглашением, разрешаются в соответствии с
      действующим законодательством Республики Беларусь и локальными нормативными правовыми актами
      ЧТУП&nbsp;«ВитЛайк Кофе».
    </p>
    <p>
      <br />
      <strong>
        Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и
        безусловно принимает их.
      </strong>
    </p>
  </>
);
