import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import styled from 'styled-components';

import { colorBlack, colorPrimary } from '@autrm/common/tokens/colors';

export const VerticalTimelineElementStyled = styled(VerticalTimelineElement)`
  .vertical-timeline-element-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .vertical-timeline-element-date {
    color: ${colorBlack};
  }

  p a {
    color: ${colorPrimary};
  }
`;
