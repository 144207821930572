import { ADAPTIVE } from '@autrm/common/tokens/screen';
import { Modal } from 'antd';
import { styled } from 'styled-components';

export const FormWrapperStyled = styled.div`
  & .ant-select {
    display: block;
    width: 100%;
  }

  & .ant-input-number {
    width: 100%;
  }
`;

export const HorizontalWrapperStyled = styled.div`
  display: flex;
  gap: 20px;
`;

export const HorizontalElementStyled = styled.div`
  flex-grow: 1;
`;

export const FormFieldStyled = styled.div`
  margin-bottom: 20px;
`;

export const SUVTypeListStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;

  label {
    display: block;
    display: flex;
    align-items: center;
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  table-layout: fixed;
  /* max-height: 70%; */
  td {
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 0;
  }
`;

export const TableWrapperStyled = styled.div`
  overflow: hidden;
  /* max-height: calc(100dvh - 500px); */
  overflow-y: auto;
  max-height: 40dvh;
`;

export const ModalStyled = styled(Modal)`
  max-height: 80dvh;
  overflow: hidden;
  width: 360px;

  ${ADAPTIVE.maxWidth.tablet} {
    width: 80%;
  }

  ${ADAPTIVE.maxWidth.desktop} {
    width: 80%;
  }
`;
