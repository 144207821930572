import { CompanyStyled } from './styled';

export function Company() {
  return (
    <CompanyStyled>
      <p>Частное торговое унитарное предприятие «ВитЛайк Кофе»</p>
      <p>
        Адрес: г. Витебск,210026, ул. Ленина д.26-А, уровень-1, неизолированный торговый объект №5
      </p>
      <p>p/c BY13ALFA30122667820010270000 в 3А0 "АЛЬФА-БАНК",</p>
      <p>г. Минск, ул. Сурганова, 43-47,</p>
      <p>СВИФТ ALFABY2X</p>
      <p>УНП 391417639</p>
      <p>ОКПО 303845352000</p>
    </CompanyStyled>
  );
}
