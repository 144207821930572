export const privacyStatement = (
  <>
    <p>
      Политика конфиденциальности действует в отношении всей информации, которую ЧТУП&nbsp;«ВитЛайк
      Кофе» может получить о пользователе во время использования им сайта www.Auto-Room.by (далее –
      Сайт). Использование Сайта означает согласие пользователя с настоящей политикой
      конфиденциальности и указанными в ней условиями обработки его персональной информации, а также
      в отношении всей информации, которую ЧТУП&nbsp;«ВитЛайк Кофе» может собрать о пользователе по
      время использования Сайта, в случае несогласия с этими условиями пользователь должен
      воздержаться от использования Сайта.
    </p>
    <p>
      <br />
      <strong>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</strong>
    </p>
    <p>
      1.1. Персональные данные - основные и дополнительные персональные данные Пользователя,
      позволяющие идентифицировать такое лицо.
      <br />
      1.2. Обработка персональных данных - любое действие (операция) или совокупность действий
      (операций), совершаемых с использованием средств автоматизации или без использования таких
      средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
      уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
      предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
      данных.
      <br />
      1.3. Конфиденциальность персональных данных - требование не допускать распространения и (или)
      предоставления информации без согласия ее обладателя или иного основания, предусмотренного
      законодательными актами Республики Беларусь;
      <br />
      1.4. Пользователь – лицо, имеющее доступ к сайту www.Auto-Room.by, посредством сети Интернет и
      использующее сайт www.Auto-Room.by.
      <br />
      1.6. Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
      пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в
      HTTP-запросе при попытке открыть страницу соответствующего сайта.
      <br />
      1.7. IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу
      IP.
    </p>
    <p>
      <br />
      <strong>2. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong>
    </p>
    <p>
      2.1. Настоящая политика конфиденциальности устанавливает обязательства ЧТУП&nbsp;«ВитЛайк
      Кофе» по неразглашению и обеспечению режима защиты конфиденциальности персональных данных,
      которые Пользователь предоставляет используя Сайт.
    </p>
    <p>
      <br />
      <strong>3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</strong>
    </p>
    <p>
      <strong>
        3.1. Персональные данные пользователя ЧТУП&nbsp;«ВитЛайк Кофе» может использовать в целях:
      </strong>
      <br />
      3.1.1. Предоставления Пользователю доступа к персонализированным ресурсам Сайта;
      <br />
      3.1.2. связь с Пользователем, в том числе направление уведомлений, запросов и информации,
      касающихся использования сайта, оказания услуг, а также обработка запросов и заявок от
      Пользователя;
      <br />
      3.1.3. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
      мошенничества;
      <br />
      3.1.4. Подтверждения достоверности и полноты персональных данных, предоставленных
      Пользователем;
      <br />
      3.1.5. Создания учетной записи для совершения заявки, если Пользователь дал согласие на
      создание учетной записи;
      <br />
      3.1.6. Уведомления Пользователя Сайта о состоянии заявки;
      <br />
      3.1.7. Предоставления Пользователю эффективной клиентской и технической поддержки при
      возникновении проблем связанных с использованием Сайта;
      <br />
      3.1.8. Предоставления Пользователю с его согласия, специальных предложений, информации о
      ценах, новостной рассылки и иных сведений от имени ЧТУП&nbsp;«ВитЛайк Кофе»;
      <br />
      3.1.9. Осуществления рекламной деятельности с согласия Пользователя;
      <br />
      3.1.10. Улучшения качества Сайта, удобства его использования, разработка сервисов и новых
      услуг.
    </p>
    <p>
      <br />
      <strong>4. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong>
    </p>
    <p>
      4.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым
      законным способом, в том числе в информационных системах персональных данных с использованием
      средств автоматизации или без использования таких средств.
      <br />
      4.2. При утрате или разглашении персональных данных ЧТУП&nbsp;«ВитЛайк Кофе» информирует
      Пользователя об утрате или разглашении персональных данных.
      <br />
      4.3. ЧТУП&nbsp;«ВитЛайк Кофе» принимает необходимые организационные и технические меры для
      защиты персональных данных Пользователя от неправомерного или случайного доступа, уничтожения,
      изменения, блокирования, копирования, распространения, а также от иных неправомерных действий
      третьих лиц.
      <br />
      4.4. ЧТУП&nbsp;«ВитЛайк Кофе» совместно с Пользователем принимает все необходимые меры по
      предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением
      персональных данных Пользователя.
      <br />
      4.5. ЧТУП&nbsp;«ВитЛайк Кофе» вправе передать персональные данные Пользователя третьим лицам в
      следующих случаях: Пользователь выразил свое согласие на такие действия; передача
      предусмотрена законодательством Республики Беларусь; передача необходима для оказания услуги
      Пользователю; в целях обеспечения возможности защиты прав и законных интересов
      ЧТУП&nbsp;«ВитЛайк Кофе» или третьих лиц в случаях, когда Пользователь нарушает
      пользовательское соглашение ЧТУП&nbsp;«ВитЛайк Кофе».
    </p>
    <p>
      <br />
      <strong>5. 5. ОБЯЗАТЕЛЬСТВА СТОРОН</strong>
    </p>
    <p>
      <strong>5.1. Пользователь обязан:</strong>
      <br />
      5.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.
      <br />
      <strong>5.2. ЧТУП&nbsp;«ВитЛайк Кофе» обязано:</strong>
      <br />
      5.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 3 настоящей
      политики конфиденциальности.
      <br />
      5.2.2. Обеспечить хранение персональных данных в тайне, не разглашать без предварительного
      письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование,
      либо разглашение иными возможными способами переданных персональных данных Пользователя.
      <br />
      5.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных
      Пользователя согласно порядку, обычно используемого для защиты такого рода информации в
      существующем деловом обороте.
      <br />
      5.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему
      Пользователю, с момента обращения или запроса Пользователя или его законного представителя
      либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в
      случае выявления недостоверных персональных данных или неправомерных действий.
    </p>
    <p>
      <br />
      <strong>6. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
    </p>
    <p>
      6.1. ЧТУП&nbsp;«ВитЛайк Кофе», не исполнившее свои обязательства, несёт ответственность за
      убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в
      соответствии с законодательством Республики Беларусь, за исключением случаев, предусмотренных
      п.п. 4.5. и 6.2. настоящей Политики Конфиденциальности.
      <br />
      <strong>
        6.2. В случае утраты или разглашения персональных данных ЧТУП&nbsp;«ВитЛайк Кофе» не несёт
        ответственность, если данные персональные данные:
      </strong>
      <br />
      6.2.1. Стали публичным достоянием до их утраты или разглашения;
      <br />
      6.2.2. Были получены от третьей стороны до момента её получения ЧТУП&nbsp;«ВитЛайк Кофе»;
      <br />
      6.2.3. Была разглашена с согласия Пользователя.
    </p>
    <p>
      <br />
      <strong>7. РАЗРЕШЕНИЕ СПОРОВ</strong>
    </p>
    <p>
      7.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта
      и ЧТУП&nbsp;«ВитЛайк Кофе», обязательным является предъявление претензии (письменного
      предложения о добровольном урегулировании спора).
      <br />
      7.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно
      уведомляет заявителя претензии о результатах рассмотрения претензии.
      <br />
      7.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в
      соответствии с действующим законодательством Республики Беларусь.
      <br />
      7.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и
      ЧТУП&nbsp;«ВитЛайк Кофе» применяется действующее законодательство Республики Беларусь.
    </p>
    <p>
      <br />
      <strong>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
    </p>
    <p>
      8.1. Настоящая политика конфиденциальности является внутренним документом ЧТУП&nbsp;«ВитЛайк
      Кофе». ЧТУП&nbsp;«ВитЛайк Кофе» при необходимости в одностороннем порядке вносит в настоящую
      политику конфиденциальности соответствующие изменения с последующим размещением новой политики
      конфиденциальности на Сайте. Пользователи самостоятельно получают на Сайте информацию об
      изменениях политики конфиденциальности. При внесении изменений, указывается дата последнего
      обновления. Новая версия политики конфиденциальности вступает в силу с момента ее размещения,
      если иное не предусмотрено новой версии.
      <br />
      8.2. В случае, если какое-либо положение настоящей политики конфиденциальности, включая любое
      предложение, пункт или их часть, признается противоречащим законодательству или
      недействительным, остальные положения, которые не противоречат законодательству, остаются в
      силе и являются действительными, а любое недействительное положение или положение, которое не
      может быть выполнено без дальнейших действий Сторон, считается удаленным, измененным,
      исправленным в той мере, в какой это необходимо для обеспечения его действительности и
      возможности выполнения.
      <br />
      8.3. Настоящая политика конфиденциальности является общедоступной и размещается в
      информационной сети Интернет на Сайте и применяется только к Сайту.&nbsp;
      <br />
      8.4. ЧТУП&nbsp;«ВитЛайк Кофе» не контролирует и не несет ответственность за сайты третьих лиц,
      на которые Пользователь может перейти по ссылкам, доступным на Сайте.
    </p>
    <p>
      <br />
      <strong>
        Все предложения или вопросы по поводу настоящей политики можно направлять по юридическому
        адресу или электронной почте.
      </strong>
    </p>
  </>
);
