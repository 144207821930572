import { Link } from 'react-router-dom';
import { VerticalTimeline } from 'react-vertical-timeline-component';

import { DockerOutlined, DollarOutlined, TruckOutlined } from '@ant-design/icons';
import { colorBlack, colorWhite } from '@autrm/common/tokens/colors';

import { VerticalTimelineElementStyled } from './styled';

export function TimelineSteps() {
  return (
    <>
      <VerticalTimeline>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--work"
          contentStyle={{
            background: colorWhite,
            color: colorBlack,
          }}
          contentArrowStyle={{
            borderRight: `7px solid  ${colorWhite}`,
          }}
          date="Этап 1"
          iconStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Обеспечительный платёж</h3>
          {/* <h4 className="vertical-timeline-element-subtitle"></h4> */}
          <p>
            Это стоимость, которая формируется в ходе торгов на аукционе. То есть Вы определяете,
            какой максимальный бюджет вы готовы поставить на тот или иной лот.
          </p>
          <p>
            Данный депозит зависит от Вашей планируемой ставки на аукционе и составляет от 1.200 до
            2.000 бел.руб.
          </p>
          <p>
            Оплачивается через интернет-эквайринг или ЕРИП и может быть возвращена в любой момент до
            выигрыша авто и в любой момент после зачисления средств на аукцион.
          </p>
          <p>
            После оплаты Вам также доступна помощь сотрудников компании в поиске и разборе
            интересного Вам автомобиля.
          </p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--work"
          contentStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#fff',
          }}
          contentArrowStyle={{
            borderRight: '7px solid  rgb(33, 150, 243)',
          }}
          date="Этап 2"
          iconStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Процесс торгов</h3>
          <p>
            Сами по себе торги проходят достаточно быстро, именно поэтому все согласовывается
            заранее и проходит без сюрпризов.
          </p>
          <p>
            Если авто ушел выше обозначенной ставки — мы его отпускаем и не превышаем обозначенный
            лимит. Никаких затрат при этом у Вас не возникает.
          </p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--work"
          date="Этап 3"
          iconStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Оплата лота</h3>
          <p>
            Вы получаете скриншот с кабинета аукциона, где будет отображена точная сумма по счету, с
            учетом аукционного сбора — эту сумму и необходимо будет оплатить по инвойсу, напрямую на
            аукцион.
          </p>
          <p>
            После заполнения банковским операционистом поля «Назначение платежа» в платежном
            поручении — проверьте, перенесли ли туда из инвойса номер аккаунт, с которого совершали
            покупку и номер лота (Buyer/Member и Lot/Stock).
          </p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--work"
          date="Этап 4"
          iconStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Комиссия аукциона</h3>
          <p>
            Аукционы берут сервисный сбор — высчитывается он из нескольких параметров — пример можно
            посмотреть в таблице{' '}
            <Link to="https://www.copart.com/content/us/en/member-fees-us-non-licensed">
              Copart
            </Link>{' '}
            и <Link to="https://www.iaai.com/marketing/standard-iaa-licensed-buyer-fees">IAA</Link>.
          </p>
          <p>
            За свои услуги по продаже техники и прочим хлопотам аукционы берут сервисный сбор —
            высчитывается он из нескольких параметров
          </p>
          <p>Данный сбор добавляется автоматически в общую стоимость авто.</p>
        </VerticalTimelineElementStyled>
        {/* <VerticalTimelineElementStyled
          className="vertical-timeline-element--work"
          date="Комиссия за перевод денег"
          iconStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Web Designer</h3>
          <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
          <p>Оплата (банковский перевод по SWIFT-коду) проводится через белорусский банк</p>
        </VerticalTimelineElementStyled> */}
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--work"
          date="Этап 5"
          iconStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Доставка в порт</h3>
          {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
          <p>
            Ждем доставки авто в порт, отгрузки и дополнительных фотографий — по ним Вы сможете
            видеть уже реальное состояние автомобиля.
          </p>
          <p>А далее формируется контейнер, включая Ваше авто.</p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--education"
          date="Этап 6"
          iconStyle={{
            background: 'rgb(233, 30, 99)',
            color: '#fff',
          }}
          icon={<DockerOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Оплата доставки</h3>
          {/* <h4 className="vertical-timeline-element-subtitle">Online Course</h4> */}
          <p>
            Второй платеж — Оплата доставки. Рассчитывается доставка, и оплата производится напрямую
            перевозчику. Учитываем что за паркентики есть доплата в зависимости от его размера.
          </p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--education"
          date="Этап 7"
          iconStyle={{
            background: 'rgb(233, 30, 99)',
            color: '#fff',
          }}
          icon={<TruckOutlined />}
        >
          <h3 className="vertical-timeline-element-title">
            Доставка в Минск, Витебск, Гомель СВХ (склад временного хранения)
          </h3>
          {/* <h4 className="vertical-timeline-element-subtitle">Certification</h4> */}
          <p>
            Ваша машина в контейнере плывет до порта Клайпеды или в порту Клайпеды происходит
            разгрузка контейнера с погрузкой на автовоз и автомобиль доставляется до Минска,
            Витебска, Гомеля СВХ. Место назначения заранее подтверждается с Менеджером путем
            текстового уведомления.
          </p>
          <p>
            Фотографии автомобиля из порта, а также статус движения судна (на котором плывёт Ваш
            авто или мотоцикл из США), Вы можете отслеживать здесь (https://auto-room.by/) по номеру
            VIN-кода.
          </p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--education"
          date="Доставка"
          iconStyle={{
            background: 'rgb(233, 30, 99)',
            color: '#fff',
          }}
          icon={<TruckOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Время доставки: 2 - 4 месяца</h3>
          {/* <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4> */}
          <p>
            В среднем доставка занимает 2-4 месяца . На время может повлиять оперативность выгрузки
            авто с аукциона, расстояние до ближайшего порта и время формирования контейнера. Еще
            больше увеличить срок доставки и ее стоимость могут лоты, купленные в штатах Калифорнии
            и Вашингтоне
          </p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--education"
          date="Таможня"
          iconStyle={{
            background: 'rgb(233, 30, 99)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          {/* <h3 className="vertical-timeline-element-title">
            Утиль сбор + хранение + таможенное оформление
          </h3> */}
          {/* <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4> */}
          <p>
            Вас оповестит менеджер и сообщит: «Ваш автомобиль доставлен на таможенный склад — можно
            подавать документы и забирать».
          </p>
          <p>
            Вместе с этим, вам напишут с пошаговую инструкцию с рекомендациями по получению
            автомобиля на СВХ.
          </p>
        </VerticalTimelineElementStyled>
        <VerticalTimelineElementStyled
          className="vertical-timeline-element--education"
          date="Финал"
          iconStyle={{
            background: 'rgb(16, 204, 82)',
            color: '#fff',
          }}
          icon={<DollarOutlined />}
        >
          <h3 className="vertical-timeline-element-title">Стоимость таможни</h3>
          {/* <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4> */}
          <p>
            Рассчитать стоимость таможенной пошлины калькулятором можно для автомобилей на нашем
            <Link to="/calculator"> сайте</Link>.
          </p>
        </VerticalTimelineElementStyled>
      </VerticalTimeline>
    </>
  );
}
