import { useContext, useEffect, useRef, useState } from 'react';

import { AutoComplete, Select } from 'antd';

import { CalcContext } from '../..';
import { getAuctions, getLocations } from '../../utils';
import { FormFieldStyled } from '../styled';

export function AuctionLocation() {
  const calcData = useContext(CalcContext);
  const auctionsOptions = getAuctions();
  // const [locationsOptions, setLocationsOptions] = useState<string[]>([]);
  const [location, setLocation] = useState<string>('');

  const inputRef = useRef<any>(null);

  // const [options, setOptions] = useState<{ value: string }[]>(
  //   getLocations(calcData?.auction).map((loc) => ({
  //     value: loc,
  //     label: loc,
  //   })),
  // );

  // useEffect(() => {
  //   const locations = getLocations(calcData?.auction);
  //   setLocationsOptions(locations);
  // }, [calcData?.auction]);

  // useEffect(() => {
  //   return setLocation(options[0]);
  // }, [locationsOptions, options]);

  useEffect(() => {
    calcData?.setLocation(location);
  }, [location, calcData]);

  // const getPanelValue = (searchText: string) =>
  //   !searchText
  //     ? []
  //     : options.filter((loc) => {
  //         const val = loc.value.includes(searchText);

  //         return {
  //           value: val,
  //           label: val,
  //         };
  //       });

  return (
    <FormFieldStyled>
      <FormFieldStyled>
        <div id="auction-label">Аукцион</div>
        <Select
          id="auction_location_select"
          key={`auction_location_select`}
          value={calcData?.auction}
          size="large"
          onChange={(a) => calcData?.setAuction(a)}
        >
          {auctionsOptions.map((auction, index) => (
            <Select.Option
              value={auction}
              key={`${auction}_${index}`}
            >
              {auction}
            </Select.Option>
          ))}
        </Select>
      </FormFieldStyled>

      <div>
        <div id="location-label">Площадка</div>
        {/* <Select
          value={location}
          size="large"
          onChange={(l) => setLocation(l)}
        >
          {locationsOptions.map((location, index) => (
            <Select.Option
              value={location}
              key={`${location}_${index}`}
            >
              {location}
            </Select.Option>
          ))}
        </Select> */}
        <AutoComplete
          id="auction_location_select"
          key={`auction_location_select`}
          value={location}
          ref={inputRef}
          allowClear
          // open
          // defaultActiveFirstOption
          // onSearch={(text) => setOptions(getPanelValue(text))}
          options={getLocations(calcData?.auction).map((loc) => ({
            value: loc,
            label: loc,
          }))}
          filterOption={(inputValue, option) =>
            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          virtual={false}
          onChange={(l) => setLocation(l)}
          // eslint-disable-next-line react/jsx-no-bind
          onSelect={(loc) => setLocation(loc)}
          onClear={() => setLocation('')}
        />
      </div>
    </FormFieldStyled>
  );
}
