import _, { isUndefined } from 'lodash';

import auctions from '@autrm/datas/auctions.json';
import deliveryAutoSea from '@autrm/datas/delivery-auto-sea.json';
import deliveryMotoSea from '@autrm/datas/delivery-moto-sea.json';
import vehicles from '@autrm/datas/vehicles.json';

import type { Vehicle } from './types';

export const USD_EUR = 0.92;
export const USD_BYN = 3.27;

export function getAuctions(): Array<string> {
  return _.uniq(_.map(auctions, (a) => a.auction));
  // return [...new Set(auctions.map((item) => item.auction))];
}

export function getAuction(auction: string | undefined, location: string | undefined): any {
  if (auction && location)
    return auctions.filter((a) => a.auction == auction && a.location == location).at(0);

  return null;
}

export function getLocations(auction: string | undefined): Array<string> {
  if (!auction) return [];

  const locations: Array<string> = auctions
    .filter((item) => item.auction === auction)
    .map((item) => item.location);

  return locations;
}

export function getPlace(location?: string) {
  return auctions.find((item) => item.location === location);
}

export function getVehicles(): Vehicle[] {
  return vehicles;
}
export function getVehicle(v: string | undefined): Vehicle | undefined {
  const vehicles = getVehicles();

  return _.find(vehicles, {
    value: v,
  });
}

export function getSeaDelivery(c: string, vehicle: any): number {
  let deliverySea;

  if (vehicle === 'auto') {
    deliverySea = deliveryAutoSea;
  }

  if (vehicle === 'moto') {
    deliverySea = deliveryMotoSea;
  }

  if (!c) return 0;
  const d = _.find(deliverySea, (i) => i.city == c);

  return d ? d.value : 0;
}

export function getOurPrice(): number {
  return 700; //BYN
}

export function getTax(): number {
  return 120; //BYN
}

export function getSVH(): number {
  return 400; //BYN
}

export function getA1Duty(price: number, volume: number): number {
  let pc = 0.54;
  let vc = 2.5;
  const eprice = price * USD_EUR;
  if (eprice > 8500) {
    pc = 0.48;
    vc = 3.5;
  }
  if (eprice > 16700) {
    pc = 0.48;
    vc = 5.5;
  }
  if (eprice > 42300) {
    pc = 0.48;
    vc = 7.5;
  }
  if (eprice > 84500) {
    pc = 0.48;
    vc = 15;
  }
  if (eprice > 169000) {
    pc = 0.48;
    vc = 20;
  }
  const pd = eprice * pc;
  const vd = volume * vc;

  return vd > pd ? vd : pd;
}

export function getA2Duty(volume: number): number {
  let k = 1.5;
  if (volume > 1000) k = 1.7;
  if (volume > 1500) k = 2.5;
  if (volume > 1800) k = 2.7;
  if (volume > 2300) k = 3;
  if (volume > 3000) k = 3.6;

  return Math.round(volume * k);
}

export function getA3Duty(volume: number): number {
  let k = 3;
  if (volume > 1000) k = 3.2;
  if (volume > 1500) k = 3.5;
  if (volume > 1800) k = 4.8;
  if (volume > 2300) k = 5;
  if (volume > 3000) k = 5.7;

  return Math.round(volume * k);
}

export function getFee(price?: number | undefined): any {
  let result = 0;

  if (isUndefined(price)) return Number(0);

  if (price >= 0 && price <= 49.99) {
    result = Number(1);
  }
  if (price >= 50 && price <= 99.99) {
    result = Number(1);
  }
  if (price >= 100 && price <= 199.99) {
    result = Number(25);
  }
  if (price >= 200 && price <= 299.99) {
    result = Number(60);
  }
  if (price >= 300 && price <= 349.99) {
    result = Number(80);
  }
  if (price >= 350 && price <= 399.99) {
    result = Number(90);
  }
  if (price >= 400 && price <= 449.99) {
    result = Number(120);
  }
  if (price >= 450 && price <= 499.99) {
    result = Number(130);
  }
  if (price >= 500 && price <= 549.99) {
    result = Number(140);
  }
  if (price >= 550 && price <= 599.99) {
    result = Number(150);
  }
  if (price >= 600 && price <= 699.99) {
    result = Number(165);
  }
  if (price >= 700 && price <= 799.99) {
    result = Number(185);
  }
  if (price >= 800 && price <= 899.99) {
    result = Number(200);
  }
  if (price >= 900 && price <= 999.99) {
    result = Number(215);
  }
  if (price >= 1000 && price <= 1199.99) {
    result = Number(230);
  }
  if (price >= 1200 && price <= 1299.99) {
    result = Number(255);
  }
  if (price >= 1300 && price <= 1399.99) {
    result = Number(275);
  }
  if (price >= 1400 && price <= 1499.99) {
    result = Number(280);
  }
  if (price >= 1500 && price <= 1599.99) {
    result = Number(290);
  }
  if (price >= 1600 && price <= 1699.99) {
    result = Number(305);
  }
  if (price >= 1700 && price <= 1799.99) {
    result = Number(315);
  }
  if (price >= 1800 && price <= 1999.99) {
    result = Number(325);
  }
  if (price >= 2000 && price <= 2399.99) {
    result = Number(355);
  }
  if (price >= 2400 && price <= 2499.99) {
    result = Number(380);
  }
  if (price >= 2500 && price <= 2999.99) {
    result = Number(400);
  }
  if (price >= 3000 && price <= 3499.99) {
    result = Number(450);
  }
  if (price >= 3500 && price <= 3999.99) {
    result = Number(500);
  }
  if (price >= 4000 && price <= 4499.99) {
    result = Number(600);
  }
  if (price >= 4500 && price <= 4999.99) {
    result = Number(625);
  }
  if (price >= 5000 && price <= 5999.99) {
    result = Number(650);
  }
  if (price >= 6000 && price <= 6499.99) {
    result = Number(675);
  }
  if (price >= 6500 && price <= 6999.99) {
    result = Number(675);
  }
  if (price >= 7000 && price <= 7499.99) {
    result = Number(700);
  }
  if (price >= 7500 && price <= 7999.99) {
    result = Number(700);
  }
  if (price >= 8000 && price <= 8499.99) {
    result = Number(725);
  }
  if (price >= 8500 && price <= 8999.99) {
    result = Number(725);
  }
  if (price >= 9000 && price <= 9999.99) {
    result = Number(725);
  }
  if (price >= 10000 && price <= 10499.99) {
    result = Number(750);
  }
  if (price >= 10500 && price <= 10999.99) {
    result = Number(750);
  }
  if (price >= 11000 && price <= 11499.99) {
    result = Number(750);
  }
  if (price >= 11500 && price <= 11999.99) {
    result = Number(760);
  }
  if (price >= 12000 && price <= 12499.99) {
    result = Number(775);
  }
  if (price >= 12500 && price <= 14999.99) {
    result = Number(790);
  }
  if (price >= 15000) {
    result = Number(price) * 0.06;
  }

  //Gate Fee
  result = result + 79;

  //Environmental Fee
  result = result + 10;

  //Virtual Bid Fee (Live)
  if (price >= 0 && price <= 99.99) {
    result = result + 0;
  }
  if (price >= 100 && price <= 499.99) {
    result = result + 49;
  }
  if (price >= 500 && price <= 999.99) {
    result = result + 59;
  }
  if (price >= 1000 && price <= 1499.99) {
    result = result + 79;
  }
  if (price >= 1500 && price <= 1999.99) {
    result = result + 89;
  }
  if (price >= 2000 && price <= 3999.99) {
    result = result + 99;
  }
  if (price >= 4000 && price <= 5999.99) {
    result = result + 109;
  }
  if (price >= 6000 && price <= 7999.99) {
    result = result + 139;
  }
  if (price >= 8000) {
    result = result + 149;
  }

  return result;
}

export function getIAAIFee(price?: number | undefined): any {
  let result = 0;

  if (isUndefined(price)) return Number(0);

  // if (price >= 0 && price <= 49.99) {
  //   result = Number(25);
  // }
  if (price >= 0 && price <= 99.99) {
    result = Number(1);
  }
  if (price >= 100 && price <= 199.99) {
    result = Number(25);
  }
  if (price >= 200 && price <= 299.99) {
    result = Number(60);
  }
  if (price >= 300 && price <= 349.99) {
    result = Number(80);
  }
  if (price >= 350 && price <= 399.99) {
    result = Number(90);
  }
  if (price >= 400 && price <= 449.99) {
    result = Number(120);
  }
  if (price >= 450 && price <= 499.99) {
    result = Number(130);
  }
  if (price >= 500 && price <= 549.99) {
    result = Number(140);
  }
  if (price >= 550 && price <= 599.99) {
    result = Number(150);
  }
  if (price >= 600 && price <= 699.99) {
    result = Number(165);
  }
  if (price >= 700 && price <= 799.99) {
    result = Number(185);
  }
  if (price >= 800 && price <= 899.99) {
    result = Number(200);
  }
  if (price >= 900 && price <= 999.99) {
    result = Number(215);
  }
  if (price >= 1000 && price <= 1199.99) {
    result = Number(230);
  }
  if (price >= 1200 && price <= 1299.99) {
    result = Number(255);
  }
  if (price >= 1300 && price <= 1399.99) {
    result = Number(275);
  }
  if (price >= 1400 && price <= 1499.99) {
    result = Number(280);
  }
  if (price >= 1500 && price <= 1599.99) {
    result = Number(290);
  }
  if (price >= 1600 && price <= 1699.99) {
    result = Number(305);
  }
  if (price >= 1700 && price <= 1799.99) {
    result = Number(315);
  }
  if (price >= 1800 && price <= 1999.99) {
    result = Number(325);
  }
  if (price >= 2000 && price <= 2399.99) {
    result = Number(355);
  }
  if (price >= 2400 && price <= 2499.99) {
    result = Number(380);
  }
  if (price >= 2500 && price <= 2999.99) {
    result = Number(400);
  }
  if (price >= 3000 && price <= 3499.99) {
    result = Number(450);
  }
  if (price >= 3500 && price <= 3999.99) {
    result = Number(500);
  }
  if (price >= 4000 && price <= 4499.99) {
    result = Number(600);
  }
  if (price >= 4500 && price <= 4999.99) {
    result = Number(625);
  }
  if (price >= 5000 && price <= 5999.99) {
    result = Number(650);
  }
  // if (price >= 6000 && price <= 6499.99) {
  //   result = Number(675);
  // }
  if (price >= 6000 && price <= 6999.99) {
    result = Number(675);
  }
  // if (price >= 7000 && price <= 7499.99) {
  //   result = Number(850);
  // }
  if (price >= 7000 && price <= 7999.99) {
    result = Number(700);
  }
  if (price >= 8000 && price <= 9999.99) {
    result = Number(725);
  }
  if (price >= 10000 && price <= 11499.99) {
    result = Number(750);
  }
  if (price >= 11500 && price <= 11999.99) {
    result = Number(760);
  }
  if (price >= 12000 && price <= 12499.99) {
    result = Number(775);
  }
  if (price >= 12500 && price <= 14499.99) {
    result = Number(790);
  }
  if (price >= 15000) {
    result = Number(price) * 0.06;
  }

  //Service Fee
  result = result + 95;

  //Environmental Fee
  result = result + 15;

  // //Proxy Bid Fee
  // if (price >= 0 && price <= 99.99) {
  //   result = result + 0;
  // }
  // if (price >= 100 && price <= 499.99) {
  //   result = result + 39;
  // }
  // if (price >= 500 && price <= 999.99) {
  //   result = result + 49;
  // }
  // if (price >= 1000 && price <= 1499.99) {
  //   result = result + 69;
  // }
  // if (price >= 1500 && price <= 1999.99) {
  //   result = result + 79;
  // }
  // if (price >= 2000 && price <= 3999.99) {
  //   result = result + 89;
  // }
  // if (price >= 4000 && price <= 5999.99) {
  //   result = result + 99;
  // }
  // if (price >= 6000 && price <= 7999.99) {
  //   result = result + 119;
  // }
  // if (price >= 8000) {
  //   result = result + 129;
  // }

  //Live Bid Fee
  if (price >= 0 && price <= 99.99) {
    result = result + 0;
  }
  if (price >= 100 && price <= 499.99) {
    result = result + 49;
  }
  if (price >= 500 && price <= 999.99) {
    result = result + 59;
  }
  if (price >= 1000 && price <= 1499.99) {
    result = result + 79;
  }
  if (price >= 1500 && price <= 1999.99) {
    result = result + 89;
  }
  if (price >= 2000 && price <= 3999.99) {
    result = result + 99;
  }
  if (price >= 4000 && price <= 5999.99) {
    result = result + 109;
  }
  if (price >= 6000 && price <= 7999.99) {
    result = result + 139;
  }
  if (price >= 8000) {
    result = result + 149;
  }

  return result;
}
