import { styled } from 'styled-components';

export const ContractStyled = styled.div`
  position: relative;
  margin: 30px 0;

  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Стандартный синтаксис */

  & h2 {
    text-align: center;
  }
  & div {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  & div span {
    flex-shink: 1;
  }
  & p {
    /* margin: 10px 0; */
    text-align: justify;
    /* text-indent: 1.5em; */
    font-size: 13px;
  }
  & h3 {
    text-align: left;
    text-transform: uppercase;
    /* margin-top: 30px; */
  }
  & h3 span {
    display: flex;
    flex-direction: row;
    justify-content: end;
    line-height: normal;
    font-weight: 400;
  }
  & li {
    margin-left: 4rem;
  }
  & table {
    width: 100%;
    border-collapse: collapse;
  }
  & table td {
    width: 50%;
    border: 1px solid #000;
  }
  & table td p {
    text-indent: 0;
    margin-left: 10px;
  }
`;

export const PechatStyled = styled.img`
  width: 185px;
  position: absolute;
  top: -45px;
  right: 70px;
`;

export const PodpisStyled = styled.img`
  width: 150px;
  position: absolute;
  top: 6px;
  right: 50px;
`;
